.wrapper {
  display: flex;
  width: 100%;
}

.card {
  border-radius: 5px;
  padding: 2.5rem;
  background: white;
  position: relative;
  display: flex;
  margin: 0.5rem;
  align-items: flex-end;
  transition: 0.4s ease-out;
  height: 250px;
  width: 450px;
}
.card:hover {
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.2);
}

.card:hover Button{
  color: #614d19;
}


.card:hover:before {
  opacity: 1;
}

.card:hover .info {
  opacity: 1;
  transform: translateY(0px);
}

.card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
}
.card .info {
  position: relative;
  z-index: 3;
  color: #614d19;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}
.card .info h1 {
  margin: 0px;
}
.card .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}
