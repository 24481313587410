.card svg {
  height: 25px;
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7e7e7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  height: 50px;
  width: 200px;
}

.card::before,
.card::after {
  position: absolute;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  transition: 0.25s linear;
  z-index: 1;
}

.card::before {
  content: '';
  left: 0;
  justify-content: flex-end;
  background-color: #e85512;
}

.card::after {
  content: '';
  right: 0;
  justify-content: flex-start;
  background-color: #e85512;
}

.card:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.card:hover span {
  opacity: 0;
  z-index: -3;
}

.card:hover::before {
  opacity: 0.5;
  transform: translateY(-100%);
}

.card:hover::after {
  opacity: 0.5;
  transform: translateY(100%);
}

.card span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  font-family: 'Fira Mono', monospace;
  font-size: 24px;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
}

.card .social {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  color: whitesmoke;
  font-size: 24px;
  text-decoration: none;
  transition: 0.2s;
}

.card .social svg {
  text-shadow: 1px 1px rgba(31, 74, 121, 0.7);
  transform: scale(1);
}

.card .social:hover {
  background-color: rgba(249, 244, 255, 0.774);
}
